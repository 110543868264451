import { useSyncExternalStore, useCallback, useRef, useMemo, useState } from 'react';
import * as filestack from 'filestack-js';
import moment from 'moment';
import {
  assetsMediaContentRetrieve,
  ContentUpload,
  MediaContent,
  AssetsContentUploadCreateRequestBody,
  MediaContentConfig,
  assetsContentUploadCreate,
  assetsMediaContentConfigCreate,
  assetsContentUploadUrlImportCreate
} from '@goldcast/api/content';
import { v6 as uuidv6 } from 'uuid';
import { useMutation } from 'react-query';
import {
  contentUploadState,
  ContentUploadState,
  ContentUploadListItem,
  ContentUploadError
} from '@/stores/contentUploadStore';
import { ContentStatesEnum, ContentError, ASSETS_FILE_PATH } from '@/Pages/VideoImportPage/constants';
import { PUSHER_STATE_MESSAGES, PUSHER_STATES } from '@/context/PusherContext/PusherContextConstants';
import { getContentUploadedState } from '@/Pages/VideoImportPage/utils';
import { showErrorToast } from '@/libs/toast/toast';
import {
  getContentStoreConfig,
  handleBeforeUnload,
  getFilesValidationErrors
} from '@/Pages/Sessions/NewContentModal/util';
import { REGEX_URL } from '@/Pages/Sessions/NewContentModal/constants';
import { currentUser } from '@/stores/user';
import { getEnvConfig } from '@/constants';
import { getFileKey, getFileNameTitleCase, isVideoFile } from '@/libs/file';
import { getLocalFtuxConfig } from '@/Pages/VideoImportPage/localFtuxConfig.util';
import { getTotalVideoDuration } from '@/libs/getVideoDuration';
import { roundToNDecimalPlaces } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { loadOrgUsageSummary } from '@/stores/orgUsageSummary';
import { useAppContext } from '@/context/AppContext/AppContext';
import useAnalytics from '@/hooks/useAnalytics';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';

interface FilestackUploadToken {
  cancel?: () => void;
  [key: string]: any;
}

export default function useContentUpload() {
  const ContentUploadStore = useSyncExternalStore(contentUploadState.subscribe, contentUploadState.getSnapshot);
  const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));
  const filesIds = useRef({});
  const uploadToken = useRef<FilestackUploadToken>({});
  const isUploadCancelled = useRef(false);
  const { trackVideoUpload } = useAnalytics();
  const { incrementDurationUsed, checkVideoUploadLimitReached, notifyUserToUpgrade } = useFreeTrialHook();
  const { logger } = useAppContext();

  // URL import related states
  const [urlInputValue, setUrlInputValue] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);

  const setUploadContent = useCallback((contentDetails: ContentUploadListItem) => {
    contentUploadState.update((state: ContentUploadState) => {
      contentDetails.totalPercentageUploaded = 0;
      state.contentUploadList[contentDetails.contentId] = {
        ...state.contentUploadList[contentDetails.contentId],
        ...contentDetails
      };
    });
  }, []);

  const initUploadedContent = useCallback(
    ({ mediaContent, uploadData: content }: { mediaContent: MediaContent; uploadData: ContentUpload }) => {
      const { contentState, error } = getContentUploadedState(mediaContent);

      const contentDetails: ContentUploadListItem = {
        projectId: content.project_id ?? '',
        contentId: content.id ?? '',
        contentUrl: content?.import_url,
        title: content.title ?? '',
        av_type: content.av_type as string,
        contentState: contentState,
        error: error,
        videoProcessingTime: mediaContent.video_processing_estimated_time,
        contentUploadedTime: mediaContent.end_time,
        clipGenerationEstimatedTime: mediaContent.clip_generation_estimated_time
      };
      setUploadContent(contentDetails);
      return contentDetails;
    },
    [setUploadContent]
  );

  const getUploadedMediaContent = useCallback(
    async (contentId: string) => {
      try {
        const mediaContent = await assetsMediaContentRetrieve({ id: contentId });
        const contentUploaded = ContentUploadStore.contentUploadList[contentId];

        contentUploadState.update((state: ContentUploadState) => {
          state.contentUploadList[contentId].contentUploadedTime = mediaContent.end_time;
          state.contentUploadList[contentId].videoProcessingTime = mediaContent.video_processing_estimated_time;
          state.contentUploadList[contentId].clipGenerationEstimatedTime = mediaContent.clip_generation_estimated_time;
        });
        return contentUploaded;
      } catch (error) {
        throw error;
      }
    },
    [ContentUploadStore]
  );

  const handleImportUrlContent = useCallback(
    (contentDetails: ContentUploadListItem) => {
      setUploadContent(contentDetails);
      getUploadedMediaContent(contentDetails.contentId);
    },
    [setUploadContent, getUploadedMediaContent]
  );

  const getContentUploadById = useCallback(
    (contentId: string | undefined) => {
      const contentUploaded = contentId && ContentUploadStore.contentUploadList[contentId];
      if (contentUploaded) {
        return ContentUploadStore.contentUploadList[contentId];
      }
      return {
        projectId: '',
        contentId: '',
        contentState: ContentStatesEnum.Uploading,
        file: undefined,
        av_type: '',
        title: '',
        totalPercentageUploaded: 0
      };
    },
    [ContentUploadStore.contentUploadList]
  );

  const updateContentState = useCallback((newState: ContentStatesEnum, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].contentState = newState;
    });
  }, []);

  const updateTotalPercentageUploaded = useCallback((totalPercentageUploaded: number, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].totalPercentageUploaded = totalPercentageUploaded;
    });
  }, []);

  const UPLOAD_CONFIG = useMemo(
    () => ({
      retry: 3,
      timeout: 60000,
      onProgress: (progress: { totalPercent: number }, contentId: string) => {
        updateTotalPercentageUploaded(progress.totalPercent, contentId);
      }
    }),
    [updateTotalPercentageUploaded]
  );

  const handleContentUploadError = useCallback((error: ContentUploadError, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].error = error;
    });
  }, []);

  const handleConfettiState = useCallback(({ showConfetti, contentId }) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].showConfetti = showConfetti;
    });
  }, []);

  const removeContentUploadEntry = useCallback((contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      delete state.contentUploadList[contentId];
    });
  }, []);

  const changeContentUploadStatus = useCallback(
    (data, type: ContentStatesEnum, contentId: string) => {
      if (data.upload_id !== contentId) {
        return;
      }

      const generationDoneState =
        data.media_type === 'AUDIO'
          ? PUSHER_STATES.CLIP_GENERATION_DONE
          : PUSHER_STATES.FTUX_CLIPS_FACIAL_RECOGNITION_DONE;

      if (data.state === generationDoneState || data.state === PUSHER_STATES.TRANSCRIPTION_DONE) {
        let currentState = ContentStatesEnum.GeneratingCompleted;
        if (type === ContentStatesEnum.Processing) {
          currentState = ContentStatesEnum.Generating;
        } else if (type === ContentStatesEnum.Generating) {
          currentState = ContentStatesEnum.GeneratingCompleted;
        } else if (type === ContentStatesEnum.GeneratingCompleted) {
          currentState = ContentStatesEnum.ContentReadyForPreview;
        }

        updateContentState(currentState, contentId);
        return;
      }

      if (data.state === PUSHER_STATES.CLIP_GENERATION_LIMIT_REACHED) {
        showErrorToast(PUSHER_STATE_MESSAGES[data.state]);
        updateContentState(ContentStatesEnum.ContentReadyForPreview, contentId);
      }

      const errorType =
        type === ContentStatesEnum.Processing ? ContentError.PROCESSING_FAILED : ContentError.GENERATING_FAILED;

      handleContentUploadError(
        {
          type: errorType,
          message: PUSHER_STATE_MESSAGES[data.state]
        },
        contentId
      );
    },
    [handleContentUploadError, updateContentState]
  );

  const trackFilestackError = useCallback(
    filestackError => {
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Failed',
        failureReason: `${filestackError?.message || ''} ${filestackError?.details?.data?.error}`
      });
    },
    [trackVideoUpload]
  );

  const generateUuids = useCallback((selectedFile, projectId, contentId, duration) => {
    if (!selectedFile) return;
    filesIds.current[getFileKey(selectedFile.name)] = {
      ...filesIds.current[getFileKey(selectedFile.name)],
      projectId,
      contentId,
      duration
    };
  }, []);

  const uploadFileContentUsingFileStack = useCallback(
    (selectedFile, contentId) => {
      if (!selectedFile) return;
      uploadToken.current = {};

      const configWithContentId = {
        ...UPLOAD_CONFIG,
        onProgress: progress => {
          updateTotalPercentageUploaded(progress.totalPercent, contentId);
        }
      };

      return client.upload(
        selectedFile,
        configWithContentId,
        getContentStoreConfig(ASSETS_FILE_PATH, filesIds.current),
        uploadToken.current
      );
    },
    [UPLOAD_CONFIG, client, updateTotalPercentageUploaded]
  );

  const storeFileDetails = useCallback(
    (contentId, projectId, title, av_type, duration) => {
      return assetsContentUploadCreate({
        body: {
          id: contentId,
          project_id: projectId,
          broadcast_type: 'SIMULIVE',
          title: title,
          media_source_type: 'UPLOAD',
          av_type: av_type,
          recording_date: moment().format('YYYY-MM-DD'),
          organization: currentUser.getSnapshot()?.organization as string,
          duration: duration ? duration.toString() : '0'
        } as AssetsContentUploadCreateRequestBody
      })
        .then(contentUploadCreateRes => {
          assetsMediaContentConfigCreate({
            body: {
              upload: contentId,
              ftux_config: getLocalFtuxConfig(contentId) || {}
            } as MediaContentConfig
          });
          return contentUploadCreateRes;
        })
        .catch(() => {
          trackVideoUpload({
            uploadType: 'Upload',
            status: 'Failed',
            failureReason: 'Error upload to API'
          });
          throw new Error('Error upload to API');
        });
    },
    [trackVideoUpload]
  );

  const onUploadFailed = useCallback(
    (reason: string, contentId: string) => {
      handleContentUploadError(
        {
          type: ContentError.UPLOAD_FAILED,
          message: reason
        },
        contentId
      );
    },
    [handleContentUploadError]
  );

  const cancelUpload = useCallback(
    (contentId: string) => {
      if (window.confirm('Are you sure? Aborting upload will discard all changes added during this upload.')) {
        isUploadCancelled.current = true;
        if (uploadToken.current.cancel) {
          uploadToken.current.cancel();
        }
        updateContentState(ContentStatesEnum.Cancelled, contentId);
        return true;
      }
      return false;
    },
    [updateContentState]
  );

  const uploadContent = useCallback(
    async contentUpload => {
      const { file: selectedFile, projectId, contentId, title, av_type, duration } = contentUpload;

      generateUuids(selectedFile, projectId, contentId, duration);
      const originalOnBeforUnload = window.onbeforeunload;

      try {
        window.onbeforeunload = handleBeforeUnload;
        trackVideoUpload({
          uploadType: 'Upload',
          status: 'Started'
        });

        const uploadedContent = await uploadFileContentUsingFileStack(selectedFile, contentId);

        if (uploadedContent.status !== 'Stored') {
          onUploadFailed('Failed to upload to Filestack', contentId);
        } else {
          const response = await storeFileDetails(contentId, projectId, title, av_type, duration);
          incrementDurationUsed(Number(response.duration));
          window.onbeforeunload = originalOnBeforUnload;

          trackVideoUpload({
            uploadType: 'Upload',
            status: 'Completed'
          });

          await getUploadedMediaContent(contentId);
          updateContentState(ContentStatesEnum.Processing, contentId);
        }
      } catch (error: any) {
        window.onbeforeunload = originalOnBeforUnload;
        if (isUploadCancelled.current) {
          isUploadCancelled.current = false;
        } else {
          onUploadFailed(
            error.message ||
              'Oops! Something went wrong with the upload. Please try again. If the issue persists, contact support for assistance',
            contentId
          );
        }
      }
    },
    [
      generateUuids,
      getUploadedMediaContent,
      incrementDurationUsed,
      onUploadFailed,
      storeFileDetails,
      trackVideoUpload,
      updateContentState,
      uploadFileContentUsingFileStack
    ]
  );

  // Functions from useUploadContent
  const handleFilesValidation = useCallback(
    (files: File[]) => {
      const fileName = files[0].name;
      const { files: fileError, global } = getFilesValidationErrors(files);
      const error = fileError[fileName] || (global && global[0]);
      if (error) {
        showErrorToast(error);
        trackVideoUpload({
          uploadType: 'Upload',
          status: 'Disabled',
          url: urlInputValue,
          failureReason: `${error}`
        });
        return true;
      }
      return false;
    },
    [urlInputValue, trackVideoUpload]
  );

  const handleContentUpload = useCallback(
    async (filesToUpload: File[]) => {
      const duration = await getTotalVideoDuration(filesToUpload);
      const contentDetails = {
        projectId: uuidv6({ msecs: new Date().getTime() }),
        contentId: uuidv6({ msecs: new Date().getTime() }),
        duration: roundToNDecimalPlaces(duration, 3),
        file: filesToUpload[0],
        title: getFileNameTitleCase(filesToUpload[0].name),
        av_type: isVideoFile(filesToUpload[0]) ? 'VIDEO' : 'AUDIO',
        contentState: ContentStatesEnum.Uploading
      };
      setUploadContent(contentDetails);
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Started'
      });
      EventBus.dispatch(CustomEvents.UploadContentStarted, {
        projectId: contentDetails.projectId,
        contentId: contentDetails.contentId
      });
    },
    [setUploadContent, trackVideoUpload]
  );

  const onFilesUpload = useCallback(
    async (files: File[]) => {
      const { value, reason } = await checkVideoUploadLimitReached(files);
      if (value) {
        trackVideoUpload({
          uploadType: 'Upload',
          status: 'Disabled',
          url: urlInputValue,
          failureReason: reason
        });
        return;
      }
      const isFileInvalid = handleFilesValidation(files);
      if (!isFileInvalid) {
        handleContentUpload(files);
      }
    },
    [checkVideoUploadLimitReached, handleFilesValidation, handleContentUpload, trackVideoUpload, urlInputValue]
  );

  function handleUrlInputChange(event: React.ChangeEvent) {
    const value = ((event.target as HTMLInputElement).value || '').trim();
    setUrlInputValue(value);
  }

  function showImportError(err) {
    trackVideoUpload({
      uploadType: 'Import',
      status: 'Failed',
      url: urlInputValue,
      failureReason: err
    });
    try {
      const parsed = JSON.parse(err as string);
      if (parsed && Array.isArray(parsed) && parsed.length > 0 && !!parsed[0]) {
        if (parsed[0].includes('Total content duration usage exceeded')) {
          notifyUserToUpgrade();
          return;
        }
        showErrorToast(parsed[0]);
      }
    } catch (e) {
      logger.error('Failed parsing import error: ', e);
      showErrorToast(
        `We can't import this video due to issues like an incorrect URL, privacy settings, multiple streams, or technical limitations. Please check and try again.`
      );
    }
  }

  const { mutate: importFromURL, isLoading: isImporting } = useMutation(assetsContentUploadUrlImportCreate, {
    onError: showImportError,
    onSuccess: data => {
      trackVideoUpload({
        uploadType: 'Import',
        status: 'Completed',
        url: urlInputValue
      });
      handleImportUrlContent({
        projectId: data.project_id ?? '',
        contentId: data.id,
        contentState: ContentStatesEnum.Processing,
        contentUrl: data?.import_url,
        title: data.title ?? '',
        av_type: data.av_type ?? ''
      });
      EventBus.dispatch(CustomEvents.UploadContentStarted, {
        projectId: data.project_id,
        contentId: data.id
      });
      loadOrgUsageSummary(currentUser.getSnapshot()?.organization as string, false);
      return;
    }
  });

  const startImportFromUrl = useCallback(() => {
    const isValid = !!urlInputValue.match(REGEX_URL);
    setIsValidLink(isValid);
    if (isValid) {
      importFromURL({
        body: {
          url: urlInputValue
        }
      });
    }
  }, [urlInputValue, importFromURL]);

  return {
    client,
    contentUploadList: ContentUploadStore.contentUploadList,
    urlInputValue,
    isValidLink,
    isImporting,
    updateContentState,
    setUploadContent,
    getContentUploadById,
    updateTotalPercentageUploaded,
    initUploadedContent,
    handleContentUploadError,
    removeContentUploadEntry,
    changeContentUploadStatus,
    getUploadedMediaContent,
    handleImportUrlContent,
    handleConfettiState,
    uploadContent,
    cancelUpload,
    trackFilestackError,
    handleUrlInputChange,
    startImportFromUrl,
    onFilesUpload
  };
}
