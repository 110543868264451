/* eslint-disable no-console */
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { IconLock } from '@tabler/icons-react';
import { HOMEPAGE_SUB_ROUTES } from './constants';
import FreeTrialUsageSummaryCard from '@/components/molecules/FreeTrial/FreeTrialUsageSummaryCard';
import useHomepageNavigationHook from '@/hooks/useHomepageNavigationHook';
import CheckoutStatusDialog from '@/Pages/Checkout/CheckoutStatusDialog';
import { useQueryParams } from '@/hooks/url/useQueryParams';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { currentUser } from '@/stores/user';
import { useAppContext } from '@/context/AppContext/AppContext';
import useAnalytics from '@/hooks/useAnalytics';

export default function Homepage(): JSX.Element {
  const [showCheckoutStatusDialog, setShowCheckoutStatusDialog] = useState(false);
  const { shouldNavigateToSplashPage } = useHomepageNavigationHook();
  const user = useSyncExternalStore(currentUser.subscribe, currentUser.getSnapshot);

  const { adminAppStore } = useAppContext();
  const { trackRecordingStudioClick } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams<{ redirect: string }>();

  useEffect(() => {
    const importEventListener = EventBus.on(CustomEvents.NavigateToContentLabImport, () => navigate('/import'));
    return () => {
      EventBus.off(CustomEvents.NavigateToContentLabImport, importEventListener);
    };
  }, []);

  useEffect(() => {
    if (queryParams?.redirect) {
      navigate(queryParams.redirect, { replace: true });
    }
  }, [queryParams]);

  useEffect(() => {
    if (shouldNavigateToSplashPage && !location.pathname.startsWith('/import')) {
      navigate('/import');
    }
  }, [location.pathname, shouldNavigateToSplashPage]);

  useEffect(() => {
    if (location.search.includes('checkout_session_id')) {
      setShowCheckoutStatusDialog(true);
    }
  }, [location.search]);

  const openCreateRecording = useCallback(() => {
    trackRecordingStudioClick();
    adminAppStore?.commit?.('eventRecordings/openCreateRecordingDialog');
  }, [adminAppStore, trackRecordingStudioClick]);

  const shouldShowRecordingStudioItem = useMemo(() => {
    return (
      (adminAppStore.currentOrg as any)?.isRecordingStudioPricingEnabled &&
      adminAppStore.orgPricingConfig?.pricing_config?.allowed_recording_event_count === undefined &&
      user?.is_content_lab_standalone
    );
  }, [adminAppStore.currentOrg, adminAppStore.orgPricingConfig, user?.is_content_lab_standalone]);

  if (shouldNavigateToSplashPage) {
    return <Outlet />;
  }

  return (
    <div className="flex flex-row">
      <div className="w-56 shrink-0 p-4">
        <div className="fixed flex w-[12rem] flex-col space-y-3">
          {HOMEPAGE_SUB_ROUTES.map(route => (
            <NavLink
              key={route.title}
              to={route.url}
              className={({ isActive }) =>
                (isActive ? 'rounded bg-slate-100 font-semibold' : 'hover:text-deep-orange-600') + ' px-2 py-1 text-sm'
              }
            >
              {route.title}
            </NavLink>
          ))}
          {shouldShowRecordingStudioItem && (
            <div
              className="flex cursor-pointer items-center gap-2 px-2 py-1 text-sm hover:text-deep-orange-600"
              onClick={openCreateRecording}
            >
              <div>Recording Studio</div>
              <IconLock size={18} />
            </div>
          )}
        </div>
        <FreeTrialUsageSummaryCard />
        {showCheckoutStatusDialog && (
          <CheckoutStatusDialog
            isOpen={showCheckoutStatusDialog}
            onClose={() => {
              setShowCheckoutStatusDialog(false);
            }}
          />
        )}
      </div>
      <Outlet />
    </div>
  );
}
