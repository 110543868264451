import { PlayerRef, Player as RemotionPlayer, RenderPoster } from '@remotion/player';
import { useCallback, useEffect, useMemo, useRef, useSyncExternalStore, useState } from 'react';
import { AbsoluteFill } from 'remotion';
import { isEmpty } from 'radash';
import { preloadVideo } from '@remotion/preload';
import MainPlayer from './MainPlayer';
import { ResponsiveContainer } from '../../../components/atoms/ResponsiveContainer';
import { getClipPlayerElementId } from '../../../Pages/Clip/ClipPlayer/ClipPlayerUtils';
import { alignedRecordings } from './stores/alignedRecordings';
import CaptionsMenu from '../components/Captions/CaptionsMenu';
import { TrackType } from './types/Track/Type';
import { SegmentsTrack } from './types/Track/SegmentsTrack';
import { SegmentType } from './types/Segment/Type';
import { Sequence } from './types/Sequence';
import { getAbsolutePlayerTime, getRelativePlayerTime } from './utils/deletesUtils';
import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '@/App/remotion/constants';
import Loader from '@/components/atoms/Loader';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { classnames, roundToNDecimalPlaces } from '@/libs/utils';
import ClipHeader from '@/components/molecules/ClipHeader';
import ClipCustomizerMenu from '@/Pages/Clip/ClipPlayer/ClipCustomizer/ClipCustomizerMenu';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import FullTranscript from '@/components/molecules/Transcript/FullTranscript';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';
import { updatePlayerStoreWithId } from '@/stores/playerV2';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import ClipSpeakersTimeline from '@/Pages/Clip/ClipPlayer/ClipSpeakersTimeline';
import { ClipPlayerSection } from '@/Pages/Clip/ClipPlayer/ClipPlayerSections/ClipPlayerSectionsTypes';
import { changeCaptionsPosition } from '@/stores/clip';

export default function ClipPlayerV2({ playerClasses = '' }: { playerClasses?: string }) {
  const remotionPlayerRef = useRef<PlayerRef>(null);
  const { clipId, clipData, playerStore } = useClipsContext();
  const transcriptStore = useTranscriptContext();
  const { createClip } = useTranscriptPage();
  const playerConfig = clipData.asset_metadata.player_config;

  const [currentSection, setCurrentSection] = useState<ClipPlayerSection>('main');
  const [timeSections, setTimeSections] = useState<
    {
      fromFrame: number;
      toFrame: number;
      section: ClipPlayerSection;
      bounds?: { fromFrame: number; toFrame: number };
    }[]
  >([]);

  const clipDeleteBounds: Sequence[] = useMemo(() => {
    if (clipData.asset_metadata.deletes && !isEmpty(clipData.asset_metadata.deletes)) {
      return Object.values(clipData.asset_metadata.deletes)
        .map(d => ({
          fromFrame: d.bounds[0],
          toFrame: d.bounds[1]
        }))
        .sort((a, b) => a.fromFrame - b.fromFrame)
        .filter(d => d.fromFrame !== d.toFrame);
    }

    return [];
  }, [clipData.asset_metadata.deletes]);

  useEffect(() => {
    const segmentsTrack = playerConfig?.playerConfig.tracks.find(t => t.type === TrackType.SEGMENTS) as SegmentsTrack;
    const segments = segmentsTrack?.segments;

    if (!segments) return;

    const newTimeSections = segments.map((s, index) => ({
      fromFrame: s.fromFrame,
      toFrame: s.toFrame,
      section: (index === 0 && s.type === SegmentType.VIDEO
        ? 'intro'
        : index === segments.length - 1 && s.type === SegmentType.VIDEO
        ? 'outro'
        : 'main') as ClipPlayerSection,
      bounds: s.type === SegmentType.COMPOSITE ? s.maxBounds : undefined
    }));

    setTimeSections(newTimeSections);

    // Track all video URLs that need to be preloaded
    const videosToPreload: string[] = [];

    // Collect video URLs from all segments
    segments.forEach(segment => {
      if (segment.type === SegmentType.VIDEO && segment.video) {
        videosToPreload.push(segment.video.details.src);
      } else if (segment.type === SegmentType.COMPOSITE) {
        const videos = segment.videos;
        videos.forEach(video => {
          videosToPreload.push(video.details.src);
        });
      }
    });

    // Preload all collected videos
    const preloadInstances = videosToPreload.map(videoUrl => preloadVideo(videoUrl));

    // Cleanup function to cancel all preloads when component unmounts
    return () => {
      preloadInstances.forEach(unpreload => {
        unpreload();
      });
    };
  }, [playerConfig?.durationInFrames]);

  useEffect(() => {
    const frame = playerStore.currentTime * FPS_24;
    const data = timeSections.find(section => frame >= section.fromFrame && frame <= section.toFrame);
    if (!data) {
      return;
    }

    setCurrentSection(data.section);
  }, [playerStore.currentTime, timeSections]);

  const playPlayer = useCallback(() => {
    remotionPlayerRef.current?.play();
    updatePlayerStoreWithId(clipId, { paused: false });
  }, [clipId]);

  const pausePlayer = useCallback(() => {
    remotionPlayerRef.current?.pause();
    updatePlayerStoreWithId(clipId, { paused: true });
  }, [clipId]);

  const isPaused = useMemo(() => playerStore.paused, [playerStore.paused]);

  const setCurrentSectionAndPlay = useCallback(
    (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => {
      // Find the target section
      const targetSection = timeSections.find(s => s.section === section);
      if (!targetSection) return;

      // Calculate the absolute time within the video
      const absoluteTime =
        time !== undefined ? targetSection.fromFrame / FPS_24 + time : targetSection.fromFrame / FPS_24;

      const timeoutWithDeletes = getAbsolutePlayerTime({
        // This is to offset for the clipData.asset_metadata.start in the ClipSpeakersTimelineView
        time: absoluteTime + (section === 'main' ? clipData.asset_metadata.start : 0),
        deleteBounds: clipDeleteBounds
      }); // Seek to the correct frame

      remotionPlayerRef.current?.seekTo(Math.round(timeoutWithDeletes * FPS_24));

      // Update section and play state
      setCurrentSection(section);
      if (shouldPlay) {
        playPlayer();
      } else {
        pausePlayer();
      }
    },
    [timeSections, playPlayer, pausePlayer, clipDeleteBounds, clipData.asset_metadata.start]
  );

  const startTimesAtSrtIndex = useMemo(() => {
    if (transcriptStore.timeSrtIndexArray.length === 0) {
      return [];
    }
    return transcriptStore.timeSrtIndexArray.map(a => a.startTime);
  }, [transcriptStore.timeSrtIndexArray]);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const showPlayerFrames = featureFlags[FeatureFlagKeys.Use_CL_Show_Player_Frames];

  const { compositionHeight, compositionWidth } = useMemo(() => {
    const videoQuality = VIDEO_QUALITY.FULL_HD;
    const compositionHeight = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].HEIGHT;
    const compositionWidth = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].WIDTH;

    return {
      compositionHeight,
      compositionWidth
    };
  }, [clipData.asset_metadata.size]);

  const onPlayerTimeUpdate = useCallback(
    e => {
      const currentTime = roundToNDecimalPlaces(e.detail.frame / FPS_24, 3);
      if (currentSection === 'main') {
        const mainSection = timeSections.find(s => s.section === 'main');
        const currentTimeForMainSection = currentTime - (mainSection?.fromFrame || 0) / FPS_24;

        const currentTimeWithDeletes = getRelativePlayerTime({
          currentTime: currentTimeForMainSection,
          deleteBounds: clipDeleteBounds
        });

        updatePlayerStoreWithId(clipId, {
          currentTime: currentTimeWithDeletes + (mainSection?.fromFrame || 0) / FPS_24
        });
      } else {
        updatePlayerStoreWithId(clipId, { currentTime });
      }
    },
    [clipId, clipDeleteBounds, currentSection, timeSections]
  );

  useEffect(() => {
    const playerRef = remotionPlayerRef.current;
    if (playerRef) {
      playerRef.addEventListener('timeupdate', onPlayerTimeUpdate);
      playerRef.addEventListener('play', playPlayer);
      playerRef.addEventListener('pause', pausePlayer);
    }

    return () => {
      if (playerRef) {
        playerRef.removeEventListener('timeupdate', onPlayerTimeUpdate);
        playerRef.removeEventListener('play', playPlayer);
        playerRef.removeEventListener('pause', pausePlayer);
      }
    };
  }, [timeSections, onPlayerTimeUpdate, playPlayer, pausePlayer]);

  useEffect(() => {
    return () => {
      if (alignedRecordings.getSnapshot().length !== 0) {
        alignedRecordings.set(() => []);
      }
    };
  }, []);

  const outroPlayerProps = useMemo(() => {
    const outroSection = timeSections.find(s => s.section === 'outro');
    const currentTime = playerStore.currentTime;
    const isInOutroSection =
      outroSection && currentTime * FPS_24 >= outroSection.fromFrame && currentTime * FPS_24 <= outroSection.toFrame;

    return {
      outroCurrentTime: isInOutroSection ? currentTime - outroSection.fromFrame / FPS_24 : 0,
      outroDuration: outroSection ? (outroSection.toFrame - outroSection.fromFrame) / FPS_24 : 0,
      isOutroPaused: isPaused,
      playOutroSection: playPlayer,
      pauseOutroSection: pausePlayer,
      // not needed for sure
      outroRef: { current: null } as any,
      onOutroTimeUpdate: () => {},
      onOutroEnd: () => {},
      onLoadedOutroData: () => {}
    };
  }, [timeSections, playerStore.currentTime, isPaused, pausePlayer, playPlayer]);

  const introPlayerProps = useMemo(() => {
    const introSection = timeSections.find(s => s.section === 'intro');
    const currentTime = playerStore.currentTime;
    const isInIntroSection =
      introSection && currentTime * FPS_24 >= introSection.fromFrame && currentTime * FPS_24 <= introSection.toFrame;

    return {
      introCurrentTime: isInIntroSection ? currentTime - introSection.fromFrame / FPS_24 : 0,
      introDuration: introSection ? (introSection.toFrame - introSection.fromFrame) / FPS_24 : 0,
      isIntroPaused: isPaused,
      playIntroSection: playPlayer,
      pauseIntroSection: pausePlayer,
      // not needed for sure
      introRef: { current: null } as any,
      onIntroTimeUpdate: () => {},
      onIntroEnd: () => {},
      onLoadedIntroData: () => {}
    };
  }, [timeSections, playerStore.currentTime, isPaused, playPlayer, pausePlayer]);

  const mainPlayerProps = useMemo(() => {
    const mainSection = timeSections.find(s => s.section === 'main');
    const currentTime = playerStore.currentTime;

    const currentTimeWithOffset = getAbsolutePlayerTime({ time: currentTime, deleteBounds: clipDeleteBounds });

    const isInMainSection =
      mainSection &&
      currentTimeWithOffset * FPS_24 >= mainSection.fromFrame &&
      currentTimeWithOffset * FPS_24 <= mainSection.toFrame;

    const mainSectionBounds = mainSection?.bounds || {
      fromFrame: mainSection?.fromFrame || 0,
      toFrame: mainSection?.toFrame || playerConfig?.durationInFrames || 0
    };

    const mainSectionDuration = (mainSectionBounds.toFrame - mainSectionBounds.fromFrame) / FPS_24;

    return {
      mainSectionRef: {
        current: { ...remotionPlayerRef.current, duration: mainSectionDuration }
      } as any,
      mainSectionCurrentTime: isInMainSection ? currentTime - mainSection.fromFrame / FPS_24 : 0,
      mainSectionDuration,
      isMainSectionPaused: isPaused,
      playMainSection: playPlayer,
      pauseMainSection: pausePlayer,
      // not needed for sure
      onMainSectionTimeUpdate: () => {},
      secondaryPlayers: [],
      setSecondaryPlayers: () => {},
      updateSecondaryPlayerTime: () => {},
      speakerImageRefs: [],
      setSpeakerImageRefs: () => {},
      remotionPlayerRef: { current: null } as any
    };
  }, [
    timeSections,
    playerStore.currentTime,
    isPaused,
    playPlayer,
    pausePlayer,
    playerConfig?.durationInFrames,
    clipDeleteBounds
  ]);

  /**
   * Update the player srt index based on time and the srt index of the words
   */
  useEffect(() => {
    if (startTimesAtSrtIndex.length === 0) return;

    const mainSectionCurrentTime = playerStore.currentTime - introPlayerProps.introDuration;
    const index = startTimesAtSrtIndex.findLastIndex(time => time <= mainSectionCurrentTime);

    updatePlayerStoreWithId(clipId, { currentSrtIndex: transcriptStore.timeSrtIndexArray[index]?.srtIndex });
  }, [
    clipId,
    playerStore.currentTime,
    startTimesAtSrtIndex,
    transcriptStore.timeSrtIndexArray,
    introPlayerProps.introDuration
  ]);

  const renderPoster: RenderPoster = useCallback(({ height, width, isBuffering }) => {
    if (isBuffering) {
      return (
        <AbsoluteFill
          className="absolute inset-0 overflow-hidden bg-slate-100"
          data-testid="composite-player-loader"
          id="composite-player-loader"
        >
          <div className="absolute inset-0 flex animate-pulse items-center justify-center overflow-hidden bg-gray-200">
            <Loader />
          </div>
        </AbsoluteFill>
      );
    }

    return (
      <AbsoluteFill style={{ backgroundColor: 'gray' }}>
        Click to play! ({height}x{width})
      </AbsoluteFill>
    );
  }, []);

  const onCreateClip = useCallback(
    (start: number, end: number) => {
      return createClip(start, end, { edits: clipData.asset_metadata.edits, deletes: clipData.asset_metadata.deletes });
    },
    [clipData.asset_metadata.deletes, clipData.asset_metadata.edits, createClip]
  );

  const playMainSectionFromTime = useCallback(
    (time: number, shouldPlay?: boolean) => {
      setCurrentSectionAndPlay('main', shouldPlay || !isPaused, time);
    },
    [setCurrentSectionAndPlay, isPaused]
  );

  const onOutlineRelease = useCallback(
    positions => {
      changeCaptionsPosition({
        clipId,
        caption_positions: {
          x: positions.left as any,
          y: positions.top as any,
          width: positions.width as any,
          height: positions.height as any
        },
        skipUpdate: false
      });
    },
    [clipId]
  );

  return (
    <div
      id={getClipPlayerElementId(clipId)}
      className={'flex h-full w-full grow flex-col items-center justify-center overflow-hidden px-3'}
    >
      <div className="flex h-full w-full flex-row items-center justify-between">
        <div className="relative h-full w-[calc(34rem-10px)] py-4">
          <FullTranscript
            playerStore={playerStore}
            videoAssetId={clipId}
            title="Recording"
            onCreateClip={onCreateClip}
            onTimeUpdate={playMainSectionFromTime}
            onPause={pausePlayer}
            fullClipOffset={introPlayerProps.introDuration}
          />
        </div>
        <div className={'flex h-full w-[calc(100%-34rem)] flex-col'}>
          <ClipHeader />
          <div
            className={classnames(
              'flex h-full w-full items-center justify-center overflow-hidden rounded-md p-4 pr-24',
              playerClasses
            )}
          >
            <ResponsiveContainer
              id="player-main-section"
              compositionHeight={compositionHeight}
              compositionWidth={compositionWidth}
              parentHeight={'80%'}
              parentWidth={'80%'}
            >
              {/* ! TODO: @AshwinBhatkal to handle the state updates better */}
              {playerConfig && !isEmpty(playerConfig) && (
                <RemotionPlayer
                  component={MainPlayer}
                  durationInFrames={playerConfig.durationInFrames || 0}
                  compositionWidth={compositionWidth}
                  compositionHeight={compositionHeight}
                  fps={FPS_24}
                  ref={remotionPlayerRef}
                  clickToPlay={false}
                  spaceKeyToPlayOrPause={false}
                  controls={showPlayerFrames}
                  inputProps={{
                    ...playerConfig,
                    showPlayerFrames,
                    onOutlineRelease,
                    CaptionsMenu
                  }}
                  style={{
                    width: '100%'
                  }}
                  showVolumeControls
                  renderPoster={renderPoster}
                  showPosterWhenBuffering
                />
              )}
            </ResponsiveContainer>
            <ClipCustomizerMenu
              isFullRecordingEdit={true}
              onTimeUpdate={playMainSectionFromTime}
              useNewRecordingsPlayer
            />
          </div>
        </div>
      </div>
      <ClipSpeakersTimeline
        outroPlayerProps={outroPlayerProps}
        mainPlayerProps={mainPlayerProps}
        introPlayerProps={introPlayerProps}
        currentSection={currentSection}
        setCurrentSectionAndPlay={setCurrentSectionAndPlay}
      />
    </div>
  );
}
