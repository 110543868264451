import { CaptionStyleOption } from '../../CanvasPlayer/CanvasPlayerDraggableCaptions/CaptionsMenu/types';
import { CaptionStyle } from '@/domains/asset';

export enum CustomizerMenuType {
  Captions = 'Captions',
  Templates = 'Templates',
  Layout = 'Layout',
  Background = 'Background',
  Speakers = 'Speakers'
}

export enum TemplatesPopupTabs {
  Preset = 'Preset',
  MyTemplates = 'My Templates'
}

export const TEMPLATES_POPUP_TABS: { name: TemplatesPopupTabs; showDefaultOnly?: boolean }[] = [
  {
    name: TemplatesPopupTabs.Preset,
    showDefaultOnly: true
  },
  {
    name: TemplatesPopupTabs.MyTemplates,
    showDefaultOnly: false
  }
];

export const CAPTIONS_POPUP_OPTIONS: CaptionStyleOption[] = [
  {
    style: CaptionStyle.Outline,
    highlight: 'text',
    textColor: '#ffffff',
    fontFamily: 'Montserrat',
    textHighlightColor: '#feed00',
    wordHighlightColor: '#000000'
  },
  {
    style: CaptionStyle.Background,
    highlight: 'none',
    textColor: '#ffffff',
    fontFamily: 'Montserrat',
    textHighlightColor: '#ffffff',
    backgroundStyleColor: '#000000',
    wordHighlightColor: '#ffffff'
  },
  {
    style: CaptionStyle.Background,
    highlight: 'text',
    textColor: '#000000',
    fontFamily: 'Poppins',
    backgroundStyleColor: '#ffffff',
    textHighlightColor: '#000000'
  },
  {
    style: CaptionStyle.Outline,
    highlight: 'box',
    textColor: '#ffffff',
    fontFamily: 'Bangers',
    backgroundColor: '#04f827',
    wordHighlightColor: '#04f827',
    textHighlightColor: '#ffffff'
  },
  {
    style: CaptionStyle.Basic,
    highlight: 'text',
    textColor: '#ffffff',
    fontFamily: 'Oswald',
    textHighlightColor: '#000000',
    backgroundStyleColor: '#000000'
  },
  {
    style: CaptionStyle.Outline,
    highlight: 'text',
    textColor: '#ffffff',
    textHighlightColor: '#04f827',
    fontFamily: 'Roboto'
  }
];
