import { User } from '@goldcast/api/content';
import { SpeakerLabelElementType, SpeakerLabelInterface } from '../../../types/Element/VideoElement';
import { ClipMetadata } from '@/domains/asset';
import { BACKGROUND_COLOR_DEFAULT, TEXT_COLOR_DEFAULT } from '@/stores/clip';
import { getFontFamilyStyle } from '@/App/remotion/libs/font';

export const getSpeakerLabelConfig = (user: User, assetMetadata: ClipMetadata) => {
  // Should support for template based speaker label

  const fontLocation = assetMetadata.font_location;
  const fontName = getFontFamilyStyle(assetMetadata.id, fontLocation);

  const output: SpeakerLabelInterface = {
    style: {
      position: 'absolute',
      bottom: '0.7cqw',
      left: '0.7cqw',
      minWidth: '40cqw',
      maxWidth: '50cqw',
      alignItems: 'flex-start',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: 'none',
      background: assetMetadata?.magicLayout?.textColor || TEXT_COLOR_DEFAULT,
      color: assetMetadata?.magicLayout?.backgroundColor || BACKGROUND_COLOR_DEFAULT,
      borderRadius: '4px',
      padding: '1cqi',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: fontName
    },
    type: SpeakerLabelElementType.CONTAINER,
    elements: [
      {
        type: SpeakerLabelElementType.TEXT,
        content: user.first_name + ' ' + user.last_name,
        style: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: 'max(2.5cqi, 12px)',
          lineHeight: 'none',
          fontFamily: 'inherit'
        }
      },

      ...(user.company
        ? [
            {
              type: SpeakerLabelElementType.TEXT,
              content: user.company,
              style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 'max(2cqi, 10px)',
                lineHeight: 'none',
                fontFamily: 'inherit'
              }
            }
          ]
        : [])
    ],
    ...(fontLocation && fontName ? { font: { location: fontLocation, name: fontName } } : {})
  };
  return output;
};
